"use client"
import React, { useContext, useState } from "react"
import { CurrentReadingIndexContext } from "@/contexts/CurrentReadingIndexContext"

export default function ReadingIndex({ children }) {
  const initialIndex = useContext(CurrentReadingIndexContext)
  const [currentIndex, setCurrentIndex] = useState(initialIndex)
  return (
    <CurrentReadingIndexContext.Provider
      value={{ currentIndex, setCurrentIndex }}
    >
      {children}
    </CurrentReadingIndexContext.Provider>
  )
}
