"use client"
import { Inter } from "@next/font/google"
import { usePathname } from "next/navigation"
import styles from "./DefayltLayout.module.scss"

const inter = Inter({
  weight: ["300", "400", "500", "600", "700"],
  subsets: ["latin"],
  display: "swap",
  variable: "--font-inter",
})

export default function Top({ children }) {
  const pathname = usePathname()

  const getOverflowStyle = () => {
    return pathname.includes("/posts") ? `visible` : `hidden`
  }
  return (
    <div
      id="top"
      className={`${styles.layout} ${
        pathname.includes("/poland2023") ? styles.layout_notop : ""
      }`}
      style={{ overflow: getOverflowStyle() }}
    >
      {children}
      <style jsx global>{`
        :root {
          --font-inter: ${inter.style.fontFamily};
        }
      `}</style>
    </div>
  )
}
