import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager", webpackExports: ["SessionProvider"] */ "/app/node_modules/next-auth/react.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["ReCaptcha"] */ "/app/node_modules/next-recaptcha-v3/lib/ReCaptcha.js");
;
import(/* webpackMode: "eager", webpackExports: ["ReCaptchaContext","ReCaptchaProvider","useReCaptchaContext"] */ "/app/node_modules/next-recaptcha-v3/lib/ReCaptchaProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["useReCaptcha"] */ "/app/node_modules/next-recaptcha-v3/lib/useReCaptcha.js");
;
import(/* webpackMode: "eager", webpackExports: ["withReCaptcha"] */ "/app/node_modules/next-recaptcha-v3/lib/withReCaptcha.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/FooterBig/FooterBig.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Layout/ReadingIndex.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Layout/Top.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Layout/Utms.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/NewHeader/NewHeader.js");
;
import(/* webpackMode: "eager", webpackExports: ["ModalProvider"] */ "/app/src/contexts/ModalProvider.js");
;
import(/* webpackMode: "eager" */ "/app/src/styles/globals.scss");
