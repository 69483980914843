"use client"

import styles from "./RegularButton.module.scss"
import { Link } from "@/i18n/routing"

export default function RegularButton(props) {
  const propStyle = props.btnStyle
  let btnStyle = []

  if (propStyle === "secondary") {
    btnStyle = ["regularbutton_secondary"]
  } else if (propStyle === "white") {
    btnStyle = ["regularbutton_white"]
  } else if (propStyle === "white_no_corner") {
    btnStyle = ["regularbutton_white", "regularbutton_white_no_corner"]
  } else if (propStyle === "secondaryGreen") {
    btnStyle = ["regularbutton_secondary"]
  } else if (propStyle === "secondaryWhite") {
    btnStyle = ["regularbutton_secondaryWhite"]
  }

  let className = `${styles.regularbutton} ${props.className}`

  btnStyle.forEach((key) => {
    if (key) className += ` ${styles[key]}`
  })

  return (
    <>
      {props.link ? (
        <Link
          href={props.link}
          className={className}
          target={props.newTabLink ? "_blank" : "_self"}
        >
          <div className={styles.regularbutton__textwrap}>
            {props.pre_text && (
              <div className={styles.regularbutton__pretext}>
                {props.pre_text}
              </div>
            )}
            <span>{props.text || "Sign up"}</span>
          </div>
        </Link>
      ) : props.justText ? (
        <div className={className}>
          <div className={styles.regularbutton__textwrap}>
            {props.pre_text && (
              <div className={styles.regularbutton__pretext}>
                {props.pre_text}
              </div>
            )}
            <span>{props.text || "Sign up"}</span>
          </div>
        </div>
      ) : (
        <button
          onClick={props.onClick}
          className={className}
          type={props.type || "button"}
        >
          <div className={styles.regularbutton__textwrap}>
            {props.pre_text && (
              <div className={styles.regularbutton__pretext}>
                {props.pre_text}
              </div>
            )}
            <span>{props.text || "Sign up"}</span>
          </div>
        </button>
      )}
    </>
  )
}
