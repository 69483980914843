export function getCookie(name) {
  let cookieValue = ""
  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";")
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim()
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === name + "=") {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1))
        break
      }
    }
  }
  return cookieValue
}

export function setCookie(name, value, days = 30, path = "/") {
  const d = new Date()
  d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days)
  const cookie =
    encodeURIComponent(name) +
    "=" +
    encodeURIComponent(value) +
    ";domain=.exante.eu;path=" +
    path +
    ";expires=" +
    d.toUTCString() // toGMTString() ?
  document.cookie = cookie
}
