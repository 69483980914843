import { defineRouting } from "next-intl/routing"
import { createNavigation } from "next-intl/navigation"

export const LOCALES = [
  "en",
  "cs",
  "de",
  "es",
  "it",
  "nl",
  "pl",
  "pt",
  "ru",
  "uk-ua",
  "zh-cn",
  "zh-tw",
]

export const DEFAULT_LOCALE = "en"

export const routing = defineRouting({
  // A list of all locales that are supported
  locales: LOCALES,

  // Used when no locale matches
  defaultLocale: DEFAULT_LOCALE,
  localePrefix: "as-needed",
  localeDetection: false,
  localeCookie: false,
})

// Lightweight wrappers around Next.js' navigation APIs
// that will consider the routing configuration
export const { Link, redirect, usePathname, useRouter, getPathname } =
  createNavigation(routing)
