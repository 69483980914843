"use client"
import Container from "../Container/Container"
import styles from "./FooterBig.module.scss"
import Image from "next/image"
// import Link from "next/link"
import Spacer from "../Spacer/Spacer"
import RegularButton from "../RegularButton/RegularButton"
import version from "@/../version.json"
import { useTranslations } from "next-intl"

export default function FooterBig() {
  const t = useTranslations()
  return (
    <footer className={styles.footer}>
      <Container>
        <div className={styles.footer__center}>
          <a href="https://exante.eu/" target="_blank" rel="noreferrer">
            <Image
              className={styles.footer__logo}
              src="/images/logo-exante.svg"
              alt="EXANTE"
              width={309}
              height={72}
            />
          </a>
          <p dangerouslySetInnerHTML={{ __html: t.raw("footer.text") }}></p>
          <div>
            <RegularButton
              link="https://exante.eu/#open-an-account"
              newTabLink
              rel="noreferrer"
              className={styles.footer__openbtn}
              text={t("footer.openAccountText")}
            ></RegularButton>
          </div>
        </div>
        <div className={styles.footer__down}>
          <div className={styles.footer__copy}>
            &copy; 2011-{new Date().getFullYear()} EXANTE . <br />
            {t("footer.copyright")}
          </div>
          <Spacer></Spacer>
          <div className={styles.footer__topWrap}>
            <a href="#top" className={styles.footer__top}>
              <Image
                src="/images/arrow-top-green.svg?2"
                alt={t("footer.toTopText")}
                width={24}
                height={24}
              />
              <span>{t("footer.toTopText")}</span>
            </a>
          </div>
        </div>
        <div
          className={styles.footer__copy}
          aria-hidden="true"
          data-version
          style={{ textAlign: "left" }}
        >
          {version.version}
        </div>
      </Container>
    </footer>
  )
}
