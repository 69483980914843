"use client"

import { createContext, useState } from "react"

//Defining context
export const ModalContext = createContext(undefined)

//Context Wrapper
export function ModalProvider({ children }) {
  const [openModal, setOpenModal] = useState(false)

  return (
    <ModalContext.Provider
      value={{
        openModal,
        setOpenModal,
      }}
    >
      {children}
    </ModalContext.Provider>
  )
}
