"use client"
import React, { useEffect } from "react"
import { setUtmsParamsToCookie } from "@/utils/utms"

export default function Utms(props) {
  useEffect(() => {
    setUtmsParamsToCookie()
  })

  return <></>
}
