"use client"
import { useState, useEffect } from "react"

import Image from "next/image"
// import { Link } from "@/i18n/routing"
import { useSearchParams } from "next/navigation"
import { useTranslations, useMessages } from "next-intl"

import Container from "../Container/Container"
import styles from "./NewHeader.module.scss"

export default function NewHeader({ isTransparent, className }) {
  const t = useTranslations()
  const messages = useMessages()
  const menu = messages.header.menu

  const [menuOpen, setMenuOpen] = useState(false)
  const [scrollPosition, setScrollPosition] = useState(0)
  const handleScroll = () => {
    const position = window.scrollY
    setScrollPosition(position)
  }

  const searchParams = useSearchParams()
  const tm = searchParams.get("tm")

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true })

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  function toggleMenu() {
    setMenuOpen(!menuOpen)
  }

  const Menu = (
    <ul className={styles.header__links}>
      {menu.map((item, index) => {
        return (
          <li
            key={menu[index].name}
            className={menu[index].name.toLowerCase().replace(" ", "-")}
          >
            {/* <a href={t(`menu.${index}.url`)}>{t(`menu.${index}.name`)}</a> */}
            <a href={menu[index].url}>{menu[index].name}</a>
          </li>
        )
      })}
    </ul>
  )

  return (
    <header
      className={`${styles.header} ${
        scrollPosition == 0 && isTransparent ? styles.header_scrolled : ""
      } ${className ? className : ""} ${tm === "0" ? styles.header_hidden : ""}
      }`}
    >
      <div className={styles.header__top}>
        <Container className={styles.header__topwrap}>
          <div className={styles.header__logo}>
            {tm === "0" ? (
              <img src="/images/logo-horizon.svg" alt="logo horizon" />
            ) : (
              <a href="/">
                <img src="/images/logo-horizon.svg" alt="logo horizon" />
              </a>
            )}
          </div>
          {/* <a href="https://exante.eu/" target="_blank" rel="noreferrer">
            <Image
              src="/images/exante-logo.svg"
              className={styles.header__exantelogo}
              alt="logo"
              width={40}
              height={40}
            />
          </a> */}
          <button
            className={styles.header__btn}
            type="button"
            onClick={toggleMenu}
          >
            <Image
              src={
                scrollPosition == 0 && isTransparent
                  ? "/images/icons/menu_blue.svg"
                  : "/images/icons/menu.svg"
              }
              alt="menu btn"
              width={28}
              height={24}
            />
          </button>
          {/* // New designer request to hide a burger and mobile menu */}
        </Container>
        <div
          className={`${styles.header__mobile_menu_wrap} ${
            menuOpen ? styles.header__mobile_menu_wrap_opened : ""
          }`}
        >
          <Container className={styles.header__topwrap}>{Menu}</Container>
        </div>
      </div>
      <Container className={styles.header__container}>
        <div className={styles.header__logo}>
          <a href="/">
            <img src="/images/logo-horizon.svg" alt="logo horizon" />
          </a>
          <p dangerouslySetInnerHTML={{ __html: t.raw("header.slogan") }}></p>
        </div>
        {Menu}
      </Container>
    </header>
  )
}
